@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@200;300;400;500;600&display=swap");

*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #cccccc;
  border-radius: 4px;
}

/* Define style for the scrollbar thumb */
*::-webkit-scrollbar-thumb {
  /* background-color: #1590fc; */
  background-color: #ff7006;
  border-radius: 4px;
}

nav {
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
}

::placeholder {
  color: gray;
  font-weight: 300;
  /* font-size: 20px; */
}
.homeParentDiv {
  position: relative;
  width: 100%;
}

main {
  position: relative;
}
/* .home h1 {
    font-size: 6vw;
} */
/* .home {
  background: url(../public/images/bg2.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  inset: 0px;
} */

.home {
  position: relative;
  z-index: 3;
}

.home::before {
  content: "";
  display: block;
  background-image: url(../public/images/bg2.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

@media screen and (max-width: 475px) {
  .home::before {
    background-size: 180% 80%;
    /* transform: translateX(-50px); */
  }
}

/* ------------------------footer--------------------------------- */
.section1 {
  border-bottom: 2px solid #ccc;
  display: grid;
  /* grid-template-columns: 10px repeat(6,1fr) 10px; */
}
.section1 > div {
  display: grid;
  place-items: center;
}
.section1 > div img {
  width: 50%;
  aspect-ratio: 3/2;
  object-fit: contain;
  /* mix-blend-mode: color-burn; */
}

.payment button {
  display: grid;
  place-items: center;
  border-radius: 8px;
  /* width: 5vw; */
  height: 40px;
  border: 1px solid gray;
}
.payment button img {
  width: 60%;
  aspect-ratio: 3/2;
  object-fit: contain;
}
.footerList > div {
  height: 100%;
}
.footerList li {
  list-style: none;
  cursor: pointer;
}
.footerList li:nth-child(1) {
  font-weight: bolder;
  font-size: larger;
  margin-bottom: 15px;
  cursor: default;
}

/* ------------------------------------navbar---------------------------- */
.navList {
  /* border-right: 3px solid black; */
  box-shadow: 2px 0px 5px #00000091;
  /* background: #e9e9e9; */
}
.ShopListt {
  border-top: 1px solid #c7c7c750;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.67);
}
.PageList {
  border-top: 1px solid #c7c7c750;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.67);
}
.ShopListt ul:last-child {
  border-right: none;
}
/* .ShopListt ul:nth-child(3) {
  border-right: none;
} */
.ShopListt ul {
  width: auto;
  border: 1px solid #c7c7c750;
  border-left: none;
  padding: 0 0 15px 0px;
}
.ShopListt ul li,
.PageList ul li {
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 40px;
  width: 100%;
  /* border-bottom: 1px solid black; */
}
.ShopListt ul li:not(:first-child):hover {
  background-color: #c7c7c750;
}
.PageList ul li:hover {
  background-color: #c7c7c750;
}

.ShopListt ul li:nth-child(1) {
  height: 50px;
  border-bottom: 1px solid #c7c7c750;
  font-weight: 700;
  cursor: default;
}
.SideMenu {
  transition: all 0.3s ease, opacity 0.5s ease;
}
.SideMenu ul {
  width: auto;
  border-bottom: 1px solid #c7c7c750;
  border-left: none;
  padding: 0 0 5px 20px;
}
.SideMenu ul li {
  padding: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-height: 40px;
  width: 100%;
  /* border-bottom: 1px solid black; */
}

.SideMenu ul li:nth-child(1) {
  height: 50px;
  margin-bottom: 5px;
  border-bottom: 1px solid #c7c7c750;
  font-weight: 700;
  cursor: default;
}

.SideMenu ul li:not(:first-child):hover {
  color: #fd5858;
}
.SideMenu ul li:not(:first-child) {
  margin-left: 1rem;
  position: relative;
}
.SideMenu ul li:not(:first-child)::before {
  content: "";
  display: inline-block;
  margin-right: 0.5rem;
  vertical-align: middle;
  width: 0.7em;
  height: 0.7em;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="%23333" d="M7.4 3.6l-.8.8 6.9 6.9H3v1.6h11.5l-6.9 6.9.8.8 8.5-8.5z"/></svg>');
}

.SideMenu ul li {
  display: inline-block;
  vertical-align: middle; /* Optional: align the text with the icon */
}
.SideMenu ul li:nth-child(1) {
  height: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #c7c7c750;
  font-weight: 700;
  cursor: default;
}

/* @media screen and (max-width: 475px) {
  .cart {
    display: none;
  }
  .input {
    grid-row: 2/3;
  }
} */
/* ----------------------------------Products--------------------------- */
.img img {
  width: 100%;
  height: 100%;
  aspect-ratio: 3/4;
  object-fit: contain;
  mix-blend-mode: color-burn;
}
/* .heartIcon{
  background: #ffffff6c;
  padding-top: 20px;
  backdrop-filter: blur(30px);
  box-shadow: 0 2px 5px 1px rgb(0, 0, 0,0.3);
} */
/* .heartIcon span{
  transition: background-color 0.9s;
} */
.productBox {
  border: 1px solid rgb(0, 0, 0, 0.1);
  border-radius: 2px;
}
.productBox:hover {
  box-shadow: 0 5px 10px 2px rgba(0, 0, 0, 0.3);
  border: none;
}

.productBox:hover .heartIcon button:nth-child(1) {
  animation: transf 0.9s 1 forwards;
  animation-delay: 0.1s;
  transition: background 0.5s ease;
}
.productBox:hover .heartIcon button:nth-child(2) {
  animation: transf 0.9s 1 forwards;
  animation-delay: 0.2s;
  transition: background 0.5s ease;
}
.productBox:hover .heartIcon button:nth-child(3) {
  animation: transf 0.9s 1 forwards;
  animation-delay: 0.3s;
  transition: background 0.5s ease;
}
@keyframes transf {
  0% {
    transform: translateY(120px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(10px);
  }
}
.ProductDetail {
  min-height: calc(100vh - 80px);
}
/* ------------------------------Product Detail Css------------------- */

.ProductImages img,
.thumbnailImg img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
}
/* --------------------------Add To Cart ----------------------- */
.scrollCart {
  scroll-snap-type: y mandatory;
  scroll-snap-align: center;
}
.scrollCart::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #cccccc;
  border-radius: 4px;
}

/* Define style for the scrollbar thumb */
.scrollCart::-webkit-scrollbar-thumb {
  /* background-color: #1590fc; */
  background-color: #ff7006;
  border-radius: 4px;
}

/* --------------------------loading page-------------------------- */
/* .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #FFF;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #FF3D00;
  border-left: 4px solid transparent;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}  */

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #fff #fff transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #ff3d00 #ff3d00;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}
.loader::before {
  width: 32px;
  height: 32px;
  border-color: #fff #fff transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
/* --------------------------form-------------------- */
form {
  font-family: "Roboto Slab", serif;
}
.formH1 {
  font-weight: 400;
}
.formInput {
  outline: none;
}
.formInput:focus {
  outline: 1px solid #00000098;
  border: none;
  box-shadow: 0px 0px 6px 1px #ea580c ,inset 0px 0px 3px 1px #ea580c;
}
